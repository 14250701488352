<template>
  <div class="card">
    <div class="card-body">
      <div
        class="background"
      >
        <!-- <div
          id="image"
          ref="printMe"
          class="certificate_body"
        >
          <div class="position_text">
            <div class="centered_text">
              <p class="first_text">
                CERTIFICAMOS QUE
              </p>
              <br><br><br><br>
              <p class="user_name">
                Carlos Roberto Pinheiro
              </p>
              <br><br><br><br>
              <p class="text_body">
                foi premiado no Congresso Norte Nordeste de Oftalmologia 2021 Digital, pela exposição de trabalho científico com o tema “Prevalência de retinopatia da prematuridade em recém-nascidos de muito baixo peso e fatores de risco associados”, que recebeu ficou em 3º lugar.
              </p>
              <br><br>
              <p class="text_body_second">
                O evento é uma realização da Sociedade Norte Nordeste de Oftalmologia,
                promovido em ambiente 100% digital, dividido em duas etapas: de 21 de agosto a 15 de setempro e 24 de novembro a 11 de dezembro
              </p>
            </div>
          </div>
          <div class="validation_code">
            <p>Código de validação: {code_validation}</p>
          </div>
        </div> -->

        <debater-certificate />
        <cordinator-certificate />
        <speaker-cerficate />
        <common-certificate />
        <scientific-work-certificate />
      </div>
      <!-- <div style="text-align: center;">
        <div
          style="margin-bottom: 10px"
          class="name"
        >
          { event.name }
        </div>
        <a
          variant="success"
          style="margin-right: 5px;"
          class="btn btn-success"
          :href="output"
          :download="'certificado.png'"
        >
          <feather-icon
            icon="AwardIcon"
          />
          baixar certificado
        </a>
        <b-button
          variant="success"
          style="margin-right: 5px;"
          class="btn btn-success"
          @click="print($refs.printMe)"
        >
          <feather-icon
            icon="AwardIcon"
          />
          Gerar certificado
        </b-button>
      </div> -->
    </div>
  </div>
</template>

<script>
import { BTable, BLink } from 'bootstrap-vue'
import DebaterCertificate from './DebaterCertificate.vue'
import CordinatorCertificate from './CordinatorCertificate.vue'
import SpeakerCerficate from './SpeakerCertificate.vue'
import CommonCertificate from './CommonCertificate.vue'
import ScientificWorkCertificate from './ScientificWorkCertificate.vue'

export default {
  components: {
    DebaterCertificate,
    CordinatorCertificate,
    SpeakerCerficate,
    CommonCertificate,
    ScientificWorkCertificate
  },
  data() {
    return {
      output: null,
      output_img: null,
    }
  },
  methods: {
    async print(element) {
      this.generating = true
      const el = element
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.
      const options = {
        type: 'dataURL',
        allowTaint: false,
        useCORS: true,
      }
      this.output = await this.$html2canvas(
        document.getElementById('image'),
        options
      )
      this.output_img = new Image()
      this.output_img.src = this.output
      console.log('src', this.output_img.src)
      this.output_img.width = 8419
      // eslint-disable-next-line no-self-assign
      this.output_img.width = this.output_img.width
      this.output_img.height = 5953
      // eslint-disable-next-line no-self-assign
      this.output_img.height = this.output_img.height
      this.output_img.onload = function () {}

      this.output = this.output_img.src
      console.log('a imagem está pronta')

      // this.generating = false
      // this.loading = false
    },

  },
}

</script>

<style scoped>
@font-face {
    font-family: 'gothampro-bold';
    src: url('./../../../assets/fonts/gotham/gothampro-bold.woff') format('woff2');
    font-weight: normal;
}
@font-face {
    font-family: 'gothampro-light';
    src: url('./../../../assets/fonts/gotham/gothampro-light.woff') format('woff2');
    font-weight: normal;
}
@font-face {
    font-family: 'gothampro-medium';
    src: url('./../../../assets/fonts/gotham/gothampro-medium.woff') format('woff2');
    font-weight: normal;
}
.background {
  position: relative;
}
.certificate_body {
  background-image: url('./../../../assets/images/certificate/cnno2021_certificado_a4.png');
  height: 5953px;
  width: 8419px;
  background-size: 100%;
  background-repeat: no-repeat;
}
.position_text {
  padding-left: 4000px;
  padding-top: 1050px;
  max-height: 5700px;
  max-width: 8000px;
}
.centered_text {
  text-align: center;
}
.first_text {
  font-size: 170px;
  height: 450px;
  font-family: "gothampro-light" !important;
}
.user_name {
  font-size: 240px;
  height: 400px;
  font-family: "gothampro-bold" !important;
}
.text_body {
  font-size: 130px;
  height: 550px;
  line-height: 200px;
  font-family: "gothampro-medium" !important;
}
.validation_code {
  font-size: 80px;
  padding-top: 2650px;
}
.text_body_second {
  font-size: 100px;
  height: 750px;
  margin-top: 450px;
  line-height: 200px;
  font-family: "gothampro-medium";
}
#image {
  position: absolute;
  top: -10000000px;
}
</style>
