import { render, staticRenderFns } from "./CommonCertificate.vue?vue&type=template&id=56f9e25a&scoped=true&"
import script from "./CommonCertificate.vue?vue&type=script&lang=js&"
export * from "./CommonCertificate.vue?vue&type=script&lang=js&"
import style0 from "./CommonCertificate.vue?vue&type=style&index=0&id=56f9e25a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56f9e25a",
  null
  
)

export default component.exports